import useOrganizationSettings from 'hooks/use-organization-settings'
import useUpdateOrganizationSettings from 'hooks/use-update-organization-settings'
import { Organization } from 'interfaces/organization'
import { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

interface OrganizationSettingsCardProps {
  orgId: string
  org?: Organization
}

export const OrganizationSettingsCard = ({
  orgId,
  org,
}: OrganizationSettingsCardProps) => {
  const history = useHistory()

  const [integrationsAllowed, setIntegrationsAllowed] = useState(false)
  const [hrEnabled, setHrEnabled] = useState(false)
  const [ssoEnabled, setSsoEnabled] = useState(false)
  const [sendLoginCodeEmails, setSendLoginCodeEmails] = useState(true)
  const [sendTransactionalEmails, setSendTransactionalEmails] = useState(true)
  const [sendMarketingEmails, setSendMarketingEmails] = useState(true)
  const [sendNewsletterEmails, setSendNewsletterEmails] = useState(true)
  const [customTemplateEnabled, setCustomTemplateEnabled] = useState(false)
  const [enableTeamLogoInQr, setEnableTeamLogoInQr] = useState(false)
  const [hideBillingPage, setHideBillingPage] = useState(false)
  const [lftForeverEnabled, setLftForeverEnabled] = useState(false)

  const updateOrganizationSettings = useUpdateOrganizationSettings({ orgId })

  const {
    data: organizationSettings,
    isLoading: isOrganizationSettingsFetching,
  } = useOrganizationSettings({ orgId: orgId })

  useEffect(() => {
    setIntegrationsAllowed(!!organizationSettings?.integrations_allowed)
    setHrEnabled(!!organizationSettings?.hr_enabled)
    setSsoEnabled(!!organizationSettings?.sso_enabled)
    setSendLoginCodeEmails(!!organizationSettings?.send_login_code_emails)
    setSendTransactionalEmails(
      !!organizationSettings?.send_transactional_emails
    )
    setSendMarketingEmails(!!organizationSettings?.send_marketing_emails)
    setSendNewsletterEmails(!!organizationSettings?.send_newsletter_emails)
    setEnableTeamLogoInQr(!!organizationSettings?.enable_team_logo_in_qr)
    setHideBillingPage(!!organizationSettings?.hide_billing)
    setLftForeverEnabled(!!organizationSettings?.lft_forever_enabled)
  }, [organizationSettings])

  const updateIntegrationsAllowed = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIntegrationsAllowed(e.target.checked)
  }

  const updateHREnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHrEnabled(e.target.checked)
  }

  const updateSSOEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSsoEnabled(e.target.checked)
  }

  const handleUpdateSettings = () => {
    updateOrganizationSettings.mutate(
      {
        ...organizationSettings,
        integrations_allowed: integrationsAllowed,
        send_login_code_emails: sendLoginCodeEmails,
        send_transactional_emails: sendTransactionalEmails,
        send_marketing_emails: sendMarketingEmails,
        send_newsletter_emails: sendNewsletterEmails,
        custom_templates_enabled: customTemplateEnabled,
        enable_team_logo_in_qr: enableTeamLogoInQr,
        hide_billing: hideBillingPage,
        lft_forever_enabled: lftForeverEnabled,
      },
      {}
    )
  }

  return (
    <Card className="mt-4">
      <Card.Header>
        <h4>Organization Settings</h4>
      </Card.Header>
      <Card.Body>
        <Button
          onClick={handleUpdateSettings}
          variant="success"
          className="mb-3 me-3"
        >
          Update Settings
        </Button>
        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={integrationsAllowed}
            onChange={updateIntegrationsAllowed}
            id="integrationsAllowedCheck"
          />
          <label
            className="form-check-label"
            htmlFor="integrationsAllowedCheck"
          >
            Allow org to setup integrations on their own?
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={customTemplateEnabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCustomTemplateEnabled(event.target.checked)
            }
            id="customTemplateEnabledCheck"
          />
          <label
            className="form-check-label"
            htmlFor="customTemplateEnabledCheck"
          >
            Allow org to use custom sms/email template in contact exchange
            prompt
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={enableTeamLogoInQr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEnableTeamLogoInQr(event.target.checked)
            }
            id="enableTeamLogoInQrCheck"
          />
          <label className="form-check-label" htmlFor="enableTeamLogoInQrCheck">
            Enable team logo in QR code for this organization
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={hideBillingPage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setHideBillingPage(event.target.checked)
            }
            id="hideBillingPage"
          />
          <label className="form-check-label" htmlFor="hideBillingPage">
            Hide Billing Page
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={sendLoginCodeEmails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSendLoginCodeEmails(event.target.checked)
            }
            id="sendLoginCodeEmailsCheck"
          />
          <label
            className="form-check-label"
            htmlFor="sendLoginCodeEmailsCheck"
          >
            Send login code emails to organization's members
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={sendTransactionalEmails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSendTransactionalEmails(event.target.checked)
            }
            id="sendTransactionalEmailsCheck"
          />
          <label
            className="form-check-label"
            htmlFor="sendTransactionalEmailsCheck"
          >
            Send general transactional emails to organization's members
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={sendMarketingEmails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSendMarketingEmails(event.target.checked)
            }
            id="sendMarketingEmailsCheck"
          />
          <label
            className="form-check-label"
            htmlFor="sendMarketingEmailsCheck"
          >
            Send marketing emails to organization's members
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={sendNewsletterEmails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSendNewsletterEmails(event.target.checked)
            }
            id="sendNewslettersEmailsCheck"
          />
          <label
            className="form-check-label"
            htmlFor="sendNewslettersEmailsCheck"
          >
            Send newsletters emails to organization's members
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={lftForeverEnabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLftForeverEnabled(event.target.checked)
            }
            id="lftForeverEnabledCheck"
          />
          <label className="form-check-label" htmlFor="lftForeverEnabledCheck">
            Enable LFT forever for this organization
          </label>
        </div>
      </Card.Body>
    </Card>
  )
}
