import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Breadcrumb,
  Col,
  Row,
  Card,
  Table,
  Button,
  Accordion,
  Modal,
  Dropdown,
} from 'react-bootstrap'
import useOrganization from 'hooks/use-organization'
import { useSelector } from 'react-redux'
import useCreateBulkUpload from 'hooks/use-create-bulk-upload'
import useOrganizationBulkUploads from 'hooks/use-organization-bulk-uploads'
import moment from 'moment'
import JSONPretty from 'react-json-pretty'
import useBulkUpload from 'hooks/use-bulk-upload'
import { BulkUpload } from 'interfaces/bulk-upload'
import { RootState } from 'index'
import { useOrganizationPageTemplates } from 'hooks/use-organization-page-templates'
import { BulkUploadModal } from 'components/bulk-upload-modal'
import toast from 'react-hot-toast'
import { useOrganizationTileGroups } from 'hooks/use-organization-tile-groups'
import useOrganizationTeams from 'hooks/use-organization-teams'
import { useTeamTileGroup } from 'hooks/use-team-tile-groups'

import { useFlatfile, Sheet, Space } from '@flatfile/react'
import { blueprint } from 'constants/flatfile-blueprint'
import { SheetConfig } from '@flatfile/api/api'
import { FlatfileBulkUploadResponse } from 'interfaces/flatfile-bulk-upload-response'

const BluePrint = blueprint as SheetConfig

const settings = {
  type: 'Contact',
  fields: [
    {
      label: 'First Name',
      key: 'first_name',
      validators: [{ validate: 'required' }],
    },
    { label: 'Last Name', key: 'last_name' },
    {
      label: 'Email',
      key: 'email',
      validators: [
        { validate: 'required' },
        { validate: 'unique' },
        {
          validate: 'regex_matches',
          regex: '^.+@.+\\..+$',
          error: 'Must be a valid email.',
        },
      ],
    },
    {
      label: 'Mobile Phone',
      key: 'phone_number',
      validators: [{ validate: 'unique' }],
    },
    { label: 'Work Phone', key: 'work_phone' },
    { label: 'Location', key: 'location' },
    { label: 'Job Title', key: 'title' },
    { label: 'Company Name', key: 'company' },
    { label: 'Photo URL', key: 'image_url' },
    {
      label: 'Designation',
      key: 'role',
      description:
        "Designate user as 'Team Admin' or 'Team Member' (Org Admin not supported in bulk upload)",
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^Team (?:Member|Admin)$',
          error: "Acceptable options are 'Team Member' or 'Team Admin'",
        },
      ],
    },
    {
      label: 'Team Name',
      key: 'team_name',
      description:
        "Provide a new or existing Team's name. If name doesn't match a current team name exactly, a new Team will be created",
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Can Edit Page Content',
      key: 'can_edit_tiles',
      description:
        'Whether or not the user can edit the page that is created for them. Defaults to true.',
    },
    {
      label: 'Block Content Id (for internal use)',
      key: 'tile_group_id',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^\\d*$',
          error: 'Only integers are accepted.',
        },
      ],
    },
    {
      label: 'Page Template Id (for internal use)',
      key: 'page_template_id',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^\\d*$',
          error: 'Only integers are accepted.',
        },
      ],
    },
    {
      label: 'Contact Card Notes',
      key: 'form_field_notes',
    },
    {
      label: 'Handle Override (Use Carefully)',
      key: 'handle',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^[A-Za-z\\d][\\w\\d]{2,31}$',
        },
      ],
    },
  ],
  managed: true,
}

const OrganizationBulkUploadsPage = () => {
  const params = useParams<any>()
  const { id: orgId } = params
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const [bulkUploadId, setBulkUploadId] = useState<any>()
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [increaseSeatCount, setIncreaseSeatCount] = useState(true)
  const [teamId, setTeamId] = useState<string | undefined>(undefined)

  const { data: org, isLoading: orgIsLoading } = useOrganization(orgId)

  const createBulkUpload = useCreateBulkUpload({ orgId })
  const { data: bulkUploads = [], isLoading: bulkUploadsIsLoading } =
    useOrganizationBulkUploads({ orgId })

  const { data: orgPageTemplates, isLoading: isOrgPageTemplatesLoading } =
    useOrganizationPageTemplates(orgId)

  const { data: orgTileGroups, isLoading: isOrgTileGroupsLoading } =
    useOrganizationTileGroups(orgId)

  const { data: teamTileGroups, isLoading: isTeamTileGroupsLoading } =
    useTeamTileGroup(teamId)
  const {
    data: teams = [],
    isLoading: isTeamsLoading,
    isError: isTeamsError,
    error: teamsError,
  } = useOrganizationTeams(orgId)

  useEffect(() => {
    if (isTeamsLoading || isTeamsError) return
    setTeamId(teams[0].id)
  }, [teams, isTeamsLoading, isTeamsError])

  const { openPortal } = useFlatfile()

  const handleBulkUploadDataProcessed = (results: {
    rawOutput: {
      valid?: boolean //
      data: BulkUpload
    }[]
  }) => {
    const { rawOutput } = results
    const validRows: BulkUpload[] = []
    const invalidRows: BulkUpload[] = []

    rawOutput.forEach((row) => {
      if (row.valid) {
        validRows.push({
          ...row.data,
          system_message: 'Pre-processed successfully.',
        })
      } else {
        invalidRows.push({
          ...row.data,
          system_message: 'Failed pre-processing.',
        })
      }
    })

    createBulkUpload.mutate({
      uploadData: {
        valid_data: validRows,
        invalid_data: invalidRows,
        increase_seat_count: increaseSeatCount,
      },
    })
  }

  const handleSeeDataClicked = (clickedBulkUploadId: string) => {
    setBulkUploadId(clickedBulkUploadId)
    setShowDetailModal(true)
  }

  const handleDetailDataModalClosed = () => {
    setShowDetailModal(false)
    setBulkUploadId(null)
  }

  const handleClickCopy = async (id: string = '') => {
    try {
      await navigator.clipboard.writeText(id)
      toast(`${id} is copied`, { icon: `👍` })
    } catch (e) {
      toast(`Failed to copy`)
    }
  }
  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
        <Breadcrumb.Item href={`/organizations/${orgId}`}>
          {org?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Bulk Uploads</Breadcrumb.Item>
      </Breadcrumb>

      <div className="d-flex flex-row">
        <Space
          config={{
            namespace: 'portal',
            metadata: {
              theme: {
                root: {
                  primaryColor: 'red',
                  textColor: 'white',
                },
                sidebar: {
                  logo: 'https://images.ctfassets.net/hjneo4qi4goj/gL6Blz3kTPdZXWknuIDVx/7bb7c73d93b111ed542d2ed426b42fd5/flatfile.svg',
                },
              },
            },
          }}
        >
          <Button onClick={openPortal}>New Bulk Upload</Button>
          <Sheet
            config={BluePrint}
            onSubmit={async ({ sheet }) => {
              const data = await sheet.allData()
              const flatfileBulkUploadResponse = data
              const { records } = flatfileBulkUploadResponse

              const transformData = (data: any) => {
                return data.map((row: any) => {
                  const transformedRow = {
                    first_name: row.values.first_name?.value || '',
                    last_name: row.values.last_name?.value || '',
                    email: row.values.email?.value || '',
                    phone_number: row.values.mobile_phone?.value || '',
                    work_phone: row.values.work_phone?.value || '',
                    location: row.values.location?.value || '',
                    title: row.values.job_title?.value || '',
                    company: row.values.company_name?.value || '',
                    role:
                      row.values.designation_team_admin_team_member?.value ||
                      '',
                    team_name: row.values.team_name?.value || '',
                    can_edit_tiles:
                      row.values.can_edit_page_content_default_true?.value ||
                      '',
                    form_field_notes:
                      row.values.contact_card_notes?.value || '',
                    page_template_id:
                      row.values.template_id_for_internal_use?.value || '',
                    system_message: row.valid
                      ? 'Pre-processed successfully.'
                      : 'Failed pre-processing.',
                  }
                  return transformedRow
                })
              }

              const validRows = transformData(
                records.filter((row: any) => row.valid)
              )
              const invalidRows = transformData(
                records.filter((row: any) => !row.valid)
              )

              createBulkUpload.mutate({
                uploadData: {
                  valid_data: validRows,
                  invalid_data: invalidRows,
                  increase_seat_count: increaseSeatCount,
                },
              })
            }}
          />
        </Space>

        {/* <FlatfileButton
          licenseKey={FLATFILE_LICENSE || ''}
          customer={{ userId: currentUser?.id || '' }}
          // @ts-expect-error inferred type does not match
          settings={settings}
          onData={async (results) => {
            handleBulkUploadDataProcessed(results)
          }}
          render={(importer, launch) => {
            return (
              <Button onClick={launch} variant="success">
                New Bulk Upload
              </Button>
            )
          }}
        /> */}
        <Button className="ms-2" href="/bulk_upload_template.csv">
          Download CSV Template
        </Button>

        <div className="d-flex flex-row align-items-center ms-2">
          <span>Automatically Increase Seat Count:</span>
          <Dropdown className="ms-2">
            <Dropdown.Toggle variant="info" className="p-1">
              {increaseSeatCount ? `Yes` : `No`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setIncreaseSeatCount(true)}>
                Yes
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setIncreaseSeatCount(false)}>
                No
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Header>
              <h4>Bulk Uploads</h4>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>Bulk Upload Id</th>
                    <th>Status</th>
                    <th>Data</th>
                    <th>System Message</th>
                    <th>Uploaded At</th>
                  </tr>
                </thead>
                <tbody>
                  {(orgIsLoading || bulkUploadsIsLoading) && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  {bulkUploads.map((bulkUpload, index) => (
                    <tr key={index} className="pointer" onClick={() => {}}>
                      <td>{bulkUpload.id}</td>
                      <td>{bulkUpload.status}</td>
                      <td>
                        <Button
                          onClick={() => handleSeeDataClicked(bulkUpload.id)}
                        >
                          See Data
                        </Button>
                      </td>
                      <td>{bulkUpload.system_message}</td>
                      <td>{moment(bulkUpload.created_at).format('lll')}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mt-4">
            <Card.Header>
              <h4>Organization Page Templates</h4>
            </Card.Header>
            <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {isOrgPageTemplatesLoading && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  {orgPageTemplates?.map((pageTemplate, index) => (
                    <tr key={index} className="pointer" onClick={() => {}}>
                      <td>
                        <Button
                          onClick={() => handleClickCopy(pageTemplate.id)}
                        >
                          {pageTemplate.id}
                        </Button>
                      </td>
                      <td>{pageTemplate.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card className="mt-4">
            <Card.Header>
              <h4>Organization Content Blocks</h4>
            </Card.Header>
            <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {isOrgTileGroupsLoading && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  {orgTileGroups?.map((tileGroup, index) => (
                    <tr key={index} className="pointer" onClick={() => {}}>
                      <td>
                        <Button onClick={() => handleClickCopy(tileGroup.id)}>
                          {tileGroup.id}
                        </Button>
                      </td>
                      <td>{tileGroup.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="mt-4">
            <Card.Header>
              <h4>Team Content Blocks</h4>
              <select
                value={teamId}
                onChange={(e) => setTeamId(e.target.value)}
              >
                <option value="">Select Team</option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </Card.Header>
            <Card.Body style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {isOrgTileGroupsLoading && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  {teamTileGroups?.map((tileGroup, index) => (
                    <tr key={index} className="pointer" onClick={() => {}}>
                      <td>
                        <Button onClick={() => handleClickCopy(tileGroup.id)}>
                          {tileGroup.id}
                        </Button>
                      </td>
                      <td>{tileGroup.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <BulkUploadModal
        showDetailModal={showDetailModal}
        handleDetailDataModalClosed={handleDetailDataModalClosed}
        bulkUploadId={bulkUploadId}
      />
    </Container>
  )
}

export default OrganizationBulkUploadsPage
