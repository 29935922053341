// export const blueprint = {
//   "name": "Sheet1",
//   "slug": "sheet_1",
//   "fields": [
//     {
//       "key": "firstName",
//       "type": "string",
//       "label": "First Name"
//     },
//     {
//       "key": "lastName",
//       "type": "string",
//       "label": "Last Name"
//     },
//     {
//       "key": "email",
//       "type": "string",
//       "label": "Email"
//     },
//     {
//       "key": "favorite-color",
//       "type": "string",
//       "label": "Favorite Color"
//     }
//   ]
// }

export const blueprint = {
  name: 'Sheet1',
  slug: 'sheet_1',
  fields: [
    {
      key: 'first_name',
      label: 'First Name*',
      type: 'string',
    },
    {
      key: 'last_name',
      label: 'Last Name',
      type: 'string',
    },
    {
      key: 'email',
      label: 'Email*',
      type: 'string',
    },
    {
      key: 'mobile_phone',
      label: 'Mobile Phone',
      type: 'string',
    },
    {
      key: 'work_phone',
      label: 'Work Phone',
      type: 'string',
    },
    {
      key: 'location',
      label: 'Location',
      type: 'string',
    },
    {
      key: 'job_title',
      label: 'Job Title',
      type: 'string',
    },
    {
      key: 'company_name',
      label: 'Company Name',
      type: 'string',
    },
    {
      key: 'photo_url_3_2_aspect_ratio_min_600x_400px_recommended',
      label: 'Photo URL (3:2 aspect ratio & min. 600x400px recommended)',
      type: 'string',
    },
    {
      key: 'designation_team_admin_team_member',
      label: 'Designation (Team Admin/Team Member)',
      type: 'string',
    },
    {
      key: 'team_name',
      label: 'Team Name',
      type: 'string',
    },
    {
      key: 'can_edit_page_content_default_true',
      label: 'Can Edit Page Content (default: true)',
      type: 'string',
    },
    {
      key: 'contact_card_notes',
      label: 'Contact Card Notes',
      type: 'string',
    },
    {
      key: 'template_id_for_internal_use',
      label: 'Template Id (for internal use)',
      type: 'string',
    },
  ],
}
